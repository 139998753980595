import { useLayoutEffect, lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { toggleApp } from "lib/store/slices/components";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { setAuthorizationHeader } from "lib/fetch/axios";
import useControlClinic from "lib/helpers/useControlClinic";
import useSpeech from "lib/helpers/useSpeech";
import ReactGA from "react-ga4";
// PAGES

import Owner from "pages/Owner";
import Login from "pages/Login";
import NotFound from "pages/Errors/NotFound";
import AuthorizedOnly from "pages/Errors/AuthorizedOnly";

import ClinicsOutlet from "components/organisms/ClinicsOutlet";
import LoadingOutlet from "components/molecules/FallbackComponent/LoadingOutlet";

import { useUnleashContext } from "@unleash/proxy-client-react";

import * as Sentry from "@sentry/browser";
// import addScripts from "lib/helpers/addScripts";

// pelayanan -> laboratorium
const LaboratoriumAntrian = lazy(
  () =>
    import(
      /* webpackChunkName: "LaboratoriumAntrian" */ "pages/Pelayanan/Laboratorium"
    )
);
const LaboratoriumPemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "LaboratoriumPemeriksaan" */ "pages/Pelayanan/Laboratorium/Pemeriksaan"
    )
);
const LaboratoriumAdministrasi = lazy(
  () =>
    import(
      /* webpackChunkName: "LaboratoriumAdministrasi" */ "pages/Pelayanan/Laboratorium/Administrasi"
    )
);

// pelayanan -> rawat jalan
const RawatJalanAntrian = lazy(
  () =>
    import(
      /* webpackChunkName: "RawatJalanAntrian" */ "pages/Pelayanan/RawatJalan"
    )
);
const RawatJalanPemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "RawatJalanPemeriksaan" */ "pages/Pelayanan/RawatJalan/Pemeriksaan"
    )
);
const RawatJalanAdministrasi = lazy(
  () =>
    import(
      /* webpackChunkName: "RawatJalanAdministrasi" */ "pages/Pelayanan/RawatJalan/Administrasi"
    )
);

// pelayanan -> oncall
const OncallAntrian = lazy(
  () => import(/* webpackChunkName: "OncallAntrian" */ "pages/Pelayanan/Oncall")
);
const OncallPemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "OncallPemeriksaan" */ "pages/Pelayanan/Oncall/Pemeriksaan"
    )
);
const OncallAdministrasi = lazy(
  () =>
    import(
      /* webpackChunkName: "OncallAdministrasi" */ "pages/Pelayanan/Oncall/Administrasi"
    )
);

// pelayanan -> Instalasi Farmasi
const ServicesInstalasiFarmasi = lazy(
  () =>
    import(
      /* webpackChunkName: "ServicesInstalasiFarmasi" */ "pages/Pelayanan/InstalasiFarmasi"
    )
);
const ServicesInstalasiFarmasiForm = lazy(
  () =>
    import(
      /* webpackChunkName: "ServicesInstalasiFarmasiForm" */ "pages/Pelayanan/InstalasiFarmasi/Form"
    )
);

// pelayanan -> Rawat Inap
const AntrianRawatInap = lazy(
  () =>
    import(
      /* webpackChunkName: "AntrianRawatInap" */ "pages/Pelayanan/RawatInap"
    )
);
const RawatInapPemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "RawatInapPemeriksaan" */ "pages/Pelayanan/RawatInap/Pemeriksaan"
    )
);
const RawatInapAdministrasi = lazy(
  () =>
    import(
      /* webpackChunkName: "RawatInapAdministrasi" */ "pages/Pelayanan/RawatInap/Administrasi"
    )
);

// pelayanan -> UGD
const AntrianUGD = lazy(
  () => import(/* webpackChunkName: "AntrianUGD" */ "pages/Pelayanan/UGD")
);
const UGDPemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "UGDPemeriksaan" */ "pages/Pelayanan/UGD/Pemeriksaan"
    )
);
const UGDAdministrasi = lazy(
  () =>
    import(
      /* webpackChunkName: "UGDAdministrasi" */ "pages/Pelayanan/UGD/Administrasi"
    )
);

// Pages Point of Sales
const PointOfSalesTransactions = lazy(
  () =>
    import(
      /* webpackChunkName: "PointOfSalesTransactions" */ "pages/PointOfSales/Transactions"
    )
);
const PointOfSalesWaiting = lazy(
  () =>
    import(
      /* webpackChunkName: "PointOfSalesWaiting" */ "pages/PointOfSales/Waiting"
    )
);
const PointOfSalesLogs = lazy(
  () =>
    import(/* webpackChunkName: "PointOfSalesLogs" */ "pages/PointOfSales/Logs")
);
const PointOfSalesReceivables = lazy(
  () =>
    import(
      /* webpackChunkName: "PointOfSalesReceivables" */ "pages/PointOfSales/Receivables"
    )
);
const PointOfSalesLogDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "PointOfSalesLogDetails" */ "pages/PointOfSales/LogDetails"
    )
);

// Notifications
const Dashboard = lazy(
  () => import(/* webpackChunkName: "Dashboard" */ "pages/Dashboard")
);
const Notifications = lazy(
  () => import(/* webpackChunkName: "Notifications" */ "pages/Notifications")
);
const UbahProfil = lazy(
  () => import(/* webpackChunkName: "UbahProfil" */ "pages/UbahProfil")
);
const UbahPassword = lazy(
  () => import(/* webpackChunkName: "UbahPassword" */ "pages/UbahPassword")
);
const Balance = lazy(
  () => import(/* webpackChunkName: "Balance" */ "pages/Balance")
);
const Calendars = lazy(
  () => import(/* webpackChunkName: "Calendars" */ "pages/Calendars")
);

// pages Manajemen Data
const ManajemenData = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenData" */ "pages/ManajemenData/Overview"
    )
);
const ManajemenDataStaff = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataStaff" */ "pages/ManajemenData/Staff"
    )
);
const ManajemenDataStaffNew = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataStaffNew" */ "pages/ManajemenData/Staff/New"
    )
);
const ManajemenDataStaffDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataStaffDetails" */ "pages/ManajemenData/Staff/Details"
    )
);
const ManajemenDataPasien = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasien" */ "pages/ManajemenData/Pasien"
    )
);
const ManajemenDataPasienDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasienDetails" */ "pages/ManajemenData/Pasien/Details"
    )
);
const ManajemenDataPasienGeneralConsent = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasienGeneralConsent" */ "pages/ManajemenData/Pasien/Details/GeneralConsent"
    )
);
const ManajemenDataPasienPackageBalance = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasienPackageBalance" */ "pages/ManajemenData/Pasien/Details/Packagebalance"
    )
);
// import ManajemenDataPasienModalGeneralConsent from "pages/ManajemenData/Pasien/Details/ModalGeneralConsent";
const ManajemenDataPasienModalGeneralConsent = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasienModalGeneralConsent" */ "pages/ManajemenData/Pasien/Details/ModalGeneralConsent"
    )
);
const ManajemenDataPasienModalMedicalRecords = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPasienModalMedicalRecords" */ "pages/ManajemenData/Pasien/Details/ModalMedicalRecords"
    )
);
const ManajemenDataPoli = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPoli" */ "pages/ManajemenData/Polies"
    )
);
const ManajemenDataPoliDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataPoliDetails" */ "pages/ManajemenData/Polies/Details"
    )
);
const ManajemenDataIks = lazy(
  () =>
    import(/* webpackChunkName: "GenerateDocument" */ "pages/ManajemenData/IKS")
);
const ManajemenDataIksDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataIksDetails" */ "pages/ManajemenData/IKS/Details"
    )
);
const ManajemenDataRuangInap = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataRuangInap" */ "pages/ManajemenData/RuangInap"
    )
);
const ManajemenDataProducts = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataProducts" */ "pages/ManajemenData/Products"
    )
);
const ManajemenDataProductDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataProductDetails" */ "pages/ManajemenData/Products/Details"
    )
);
const ManajemenDataProductUnits = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataProductUnits" */ "pages/ManajemenData/ProductUnits"
    )
);
const ManajemenDataProductTypes = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataProductTypes" */ "pages/ManajemenData/ProductTypes"
    )
);

const ManajemenDataSupplier = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataSupplier" */ "pages/ManajemenData/Suppliers"
    )
);
const ManajemenDataSupplierPurchaseOrder = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataSupplierPurchaseOrder" */ "pages/ManajemenData/Suppliers/PurchaseOrder"
    )
);
const ManajemenDataSupplierCreateUpdate = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataSupplierCreateUpdate" */ "pages/ManajemenData/Suppliers/CreateUpdateForm"
    )
);
const ManajemenDataSupplierView = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataSupplierView" */ "pages/ManajemenData/Suppliers/ListPiutang"
    )
);

const ManajemenDataDocumentTemplates = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataDocumentTemplates" */ "pages/ManajemenData/Documents"
    )
);
const ManajemenDataDocumentTemplatesCreateUpdate = lazy(
  () =>
    import(
      /* webpackChunkName: "ManajemenDataDocumentTemplatesCreateUpdate" */ "pages/ManajemenData/Documents/CreateUpdate"
    )
);

const Histories = lazy(
  () => import(/* webpackChunkName: "Histories" */ "pages/Histories")
);

const IntegrationsBPJSAntreanOnlineSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSAntreanOnlineSettings" */ "pages/Integration/BPJS/AntreanOnline/Settings"
    )
);
const IntegrationsBPJSiCareSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSiCareSettings" */ "pages/Integration/BPJS/ICARE/Settings"
    )
);
const IntegrationsBPJSVCLAIMSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSSettings" */ "pages/Integration/BPJS/VCLAIM/Settings"
    )
);
const IntegrationsBPJSVCLAIMSepList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMSepList" */ "pages/Integration/BPJS/VCLAIM/Sep/List"
    )
);
const IntegrationsBPJSVCLAIMSepDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMSepDetails" */ "pages/Integration/BPJS/VCLAIM/Sep/Details"
    )
);
const IntegrationsBPJSVCLAIMRencanaKontrolList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMRencanaKontrolList" */ "pages/Integration/BPJS/VCLAIM/RencanaKontrol/List"
    )
);

const IntegrationsBPJSVCLAIMRujukanList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMRujukanList" */ "pages/Integration/BPJS/VCLAIM/Rujukan/List"
    )
);
const IntegrationsBPJSVCLAIMRujukanKhususList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMRujukanKhususList" */ "pages/Integration/BPJS/VCLAIM/RujukanKhusus/List"
    )
);
const IntegrationsBPJSVCLAIMRujukBalikList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMRujukBalikList" */ "pages/Integration/BPJS/VCLAIM/RujukBalik/List"
    )
);
const IntegrationsBPJSVCLAIMLembarPengajuanKlaimList = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSVCLAIMLembarPengajuanKlaimList" */ "pages/Integration/BPJS/VCLAIM/LembarPengajuanKlaim/List"
    )
);

const IntegrationsBPJSSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSSettings" */ "pages/Integration/BPJS/PCARE/Settings"
    )
);
const IntegrationsBPJSEncounter = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsBPJSEncounter" */ "pages/Integration/BPJS/PCARE/ListKunjungan"
    )
);
const IntegrationsLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsLandingPage" */ "pages/Integration/LandingPage"
    )
);
const IntegrationsSatuSehat = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsSatuSehat" */ "pages/Integration/SatuSehat"
    )
);
const IntegrationsSatuSehatWelcome = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsSatuSehatWelcome" */ "pages/Integration/SatuSehat/Welcome"
    )
);
const Crm = lazy(
  () => import(/* webpackChunkName: "Crm" */ "pages/Integration/Crm")
);
const MarketingCampaign = lazy(
  () =>
    import(
      /* webpackChunkName: "MarketingCampaign" */ "pages/Integration/Crm/MarketingCampaign"
    )
);
const CrmRiwayat = lazy(
  () =>
    import(/* webpackChunkName: "CrmRiwayat" */ "pages/Integration/Crm/Logs")
);
const CommunicationTemplateLists = lazy(
  () =>
    import(
      /* webpackChunkName: "CommunicationTemplateLists" */ "pages/Integration/Crm/Communication/TemplateLists"
    )
);
const CommunicationTemplateCreateUpdate = lazy(
  () =>
    import(
      /* webpackChunkName: "CommunicationTemplateCreateUpdate" */ "pages/Integration/Crm/Communication/TemplateCreateUpdate"
    )
);
const CrmSurvey = lazy(
  () =>
    import(/* webpackChunkName: "CrmSurvey" */ "pages/Integration/Crm/Survey")
);
const CrmFeedback = lazy(
  () =>
    import(
      /* webpackChunkName: "CrmFeedback" */ "pages/Integration/Crm/Feedback"
    )
);
const CrmChatbot = lazy(
  () =>
    import(/* webpackChunkName: "CrmChatbot" */ "pages/Integration/Crm/Chatbot")
);
const CrmLoyalty = lazy(
  () =>
    import(/* webpackChunkName: "CrmLoyalty" */ "pages/Integration/Crm/Loyalty")
);
const CrmOmnichannel = lazy(
  () =>
    import(
      /* webpackChunkName: "CrmOmnichannel" */ "pages/Integration/Crm/Omnichannel"
    )
);
const CrmSegmentation = lazy(
  () =>
    import(
      /* webpackChunkName: "CrmSegmentation" */ "pages/Integration/Crm/Segmentation"
    )
);
const Accountings = lazy(
  () =>
    import(
      /* webpackChunkName: "Accountings" */ "pages/Integration/Accountings"
    )
);

// reports pelayanan
const ReportsPelayananRawatJalan = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananRawatJalan" */ "pages/Reports/Pelayanan/RawatJalan"
    )
);
const ReportsPelayananRawatJalanKunjungan = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananRawatJalanKunjungan" */ "pages/Reports/Pelayanan/RawatJalan/Kunjungan"
    )
);
const ReportsPelayananUGD = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananUGD" */ "pages/Reports/Pelayanan/UGD"
    )
);
const ReportsPelayananUGDKunjungan = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananUGDKunjungan" */ "pages/Reports/Pelayanan/UGD/Kunjungan"
    )
);
const ReportsPelayananOncall = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananOncall" */ "pages/Reports/Pelayanan/Oncall"
    )
);
const ReportsPelayananOncallKunjungan = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPelayananOncallKunjungan" */ "pages/Reports/Pelayanan/Oncall/Kunjungan"
    )
);

// reports sales
const ReportsPenjualanRawatJalanOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanRawatJalanOverview" */ "pages/Reports/Penjualan/RawatJalanOverview"
    )
);
const ReportsPenjualanRawatJalanDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanRawatJalanDetails" */ "pages/Reports/Penjualan/RawatJalanDetails"
    )
);

const ReportsPenjualanRawatInapOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanRawatInapOverview" */ "pages/Reports/Penjualan/RawatInapOverview"
    )
);
const ReportsPenjualanRawatInapDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanRawatInapDetails" */ "pages/Reports/Penjualan/RawatInapDetails"
    )
);

const ReportsPenjualanUGDOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanUGDOverview" */ "pages/Reports/Penjualan/UGDOverview"
    )
);
const ReportsPenjualanUGDDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanUGDDetails" */ "pages/Reports/Penjualan/UGDDetails"
    )
);

const ReportsPenjualanOncallOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanOncallOverview" */ "pages/Reports/Penjualan/OncallOverview"
    )
);
const ReportsPenjualanOncallDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanOncallDetails" */ "pages/Reports/Penjualan/OncallDetails"
    )
);

const ReportsPenjualanLangsungOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanLangsungOverview" */ "pages/Reports/Penjualan/PenjualanLangsungOverview"
    )
);
const ReportsPenjualanLangsungDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenjualanLangsungDetails" */ "pages/Reports/Penjualan/PenjualanLangsungDetails"
    )
);
const ReportsPenerbitanSurat = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsPenerbitanSurat" */ "pages/Reports/PenerbitanSurat"
    )
);
const ReportsBagiHasilTindakan = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsBagiHasilTindakan" */ "pages/Reports/BagiHasilTindakan"
    )
);
const ReportsApotek = lazy(
  () => import(/* webpackChunkName: "ReportsApotek" */ "pages/Reports/Apotek")
);
const LogsApotek = lazy(
  () => import(/* webpackChunkName: "LogsApotek" */ "pages/Reports/Apotek/Logs")
);
const PuyerApotek = lazy(
  () =>
    import(/* webpackChunkName: "PuyerApotek" */ "pages/Reports/Apotek/Puyer")
);
const ReportsApotekDrugsCondition = lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsApotekDrugsCondition" */ "pages/Reports/Apotek/KondisiStok"
    )
);
const SettingsGeneral = lazy(
  () =>
    import(/* webpackChunkName: "SettingsGeneral" */ "pages/Settings/General")
);
const SettingsLogo = lazy(
  () => import(/* webpackChunkName: "SettingsLogo" */ "pages/Settings/Logo")
);
const SettingsInstalasiFarmasi = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsInstalasiFarmasi" */ "pages/Settings/InstalasiFarmasi"
    )
);
const SettingsCodingRM = lazy(
  () =>
    import(/* webpackChunkName: "SettingsCodingRM" */ "pages/Settings/CodingRM")
);
const SettingsPointOfSales = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsPointOfSales" */ "pages/Settings/PointOfSales"
    )
);
const SettingsBPJS = lazy(
  () => import(/* webpackChunkName: "SettingsBPJS" */ "pages/Settings/BPJS")
);
const SettingsRawatInap = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsRawatInap" */ "pages/Settings/RawatInap"
    )
);
const SettingsHakKewajibanPasien = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsHakKewajibanPasien" */ "pages/Settings/HakKewajibanPasien"
    )
);
const SettingsUGD = lazy(
  () => import(/* webpackChunkName: "SettingsUGD" */ "pages/Settings/UGD")
);
const SettingsOnCall = lazy(
  () => import(/* webpackChunkName: "SettingsOnCall" */ "pages/Settings/OnCall")
);
const SettingsCalendars = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsCalendars" */ "pages/Settings/Calendars"
    )
);
const SettingsKiosk = lazy(
  () => import(/* webpackChunkName: "SettingsKiosk" */ "pages/Settings/Kiosk")
);
const SettingsAnnouncers = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsAnnouncers" */ "pages/Settings/Announcers"
    )
);
const SettingsPagePemeriksaan = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsPagePemeriksaan" */ "pages/Settings/PagePemeriksaan"
    )
);
const Blogs = lazy(() => import(/* webpackChunkName: "Blogs" */ "pages/Blogs"));
const About = lazy(() => import(/* webpackChunkName: "About" */ "pages/About"));
const BlogsSlug = lazy(
  () => import(/* webpackChunkName: "BlogsSlug" */ "pages/Blogs/Slug")
);

// declare global {
//   interface Window {
//     dataLayer: any;
//   }
// }

export default function App() {
  useControlClinic();
  useSpeech();

  const updateContext = useUnleashContext();

  const location = useLocation();

  const locationState = location.state as { backgroundLocation?: Location };

  const clinics = useAppSelector((state) => state.clinics);
  const users = useAppSelector((state) => state.users);
  const components = useAppSelector((state) => state.components);

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (process.env.REACT_APP_STAGING === "PRODUCTION") {
      ReactGA.initialize(
        process.env.REACT_APP_MAIN_APP_GTAG_ANALYTICS as string
      );

      // (function (w: any, d: any, s: any, l: any, i: any) {
      //   w[l] = w[l] || [];
      //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      //   var f = d.getElementsByTagName(s)[0],
      //     j = d.createElement(s),
      //     dl = l !== "dataLayer" ? "&l=" + l : "";
      //   j.async = true;
      //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      //   f.parentNode.insertBefore(j, f);
      // })(
      //   window,
      //   document,
      //   "script",
      //   "dataLayer",
      //   process.env.REACT_APP_MAIN_APP_GTAG_MANAGER
      // );

      // addScripts(
      //   `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MAIN_APP_GTAG_ANALYTICS}`,
      //   { async: true }
      // );

      // addScripts(
      //   `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MAIN_APP_GTAG_UA}`,
      //   { async: true }
      // );

      // window.dataLayer = window.dataLayer || [];

      // gtag("js", new Date());

      // gtag("config", process.env.REACT_APP_MAIN_APP_GTAG_ANALYTICS);
      // gtag("config", process.env.REACT_APP_MAIN_APP_GTAG_UA);

      // if (clinics.selected) {
      //   window.dataLayer.push({
      //     clinicName: clinics.selected.name,
      //   });
      // }
      // if (users.profile) {
      //   window.dataLayer.push({
      //     usersName: users.profile.fullName,
      //   });
      // }
    }
  }, [clinics.selected, users]);

  useLayoutEffect(() => {
    if (users.token) {
      setAuthorizationHeader(users.token);
    }
    setTimeout(() => {
      dispatch(toggleApp(true));
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, users]);

  useLayoutEffect(() => {
    document.body!.classList.remove("dark");
    document.body!.classList.remove("light");

    if (components.app.colorScheme === "system-default") {
      const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

      document.body!.classList.add(isDark ? "dark" : "light");
    } else {
      document.body!.classList.add(components.app.colorScheme);
    }
  }, [components.app.colorScheme]);

  useEffect(() => {
    if (clinics.selected) {
      updateContext({ userId: clinics.selected?._id });
    }
    // if (users?.profile?.idUser) {
    //   updateContext({ sessionId: users?.profile?.idUser });
    // }
  }, [clinics, updateContext]);

  useEffect(() => {
    if (users && clinics) {
      Sentry.setContext("User", {
        name: users.profile?.fullName || "Belum input nama",
        email: users.profile?.email,
        entity: users.profile?.entity,
      });
      Sentry.setContext("Clinic", {
        name: clinics.selected?.name,
        email: clinics.selected?.email,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinics, users]);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      {!!users.isAdminMejadokter && (
        <span className="fixed top-0 right-0 z-50 pointer-events-none cursor-default">
          <span className="text-xxs uppercase px-2 py-1 font-bold bg-green-300 text-gray-900">
            Act as admin
          </span>
        </span>
      )}
      <Routes location={locationState?.backgroundLocation || location}>
        <Route index element={<Owner />} />
        <Route
          path="ubah-profil"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <UbahProfil />
            </Suspense>
          }
        />
        <Route
          path="ubah-password"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <UbahPassword />
            </Suspense>
          }
        />

        <Route path="clinics">
          <Route index element={<Owner />} />

          <Route path=":id" element={<ClinicsOutlet />}>
            <Route
              index
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="dashboard"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="notifications"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Notifications />
                </Suspense>
              }
            />

            <Route
              path="balance"
              element={
                <Suspense fallback={<LoadingOutlet />}>
                  <Balance />
                </Suspense>
              }
            />

            {(users.profile?.entity === "owner" ||
              !!users.profile?.authority?.appointment) && (
              <Route path="calendars">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Calendars />
                    </Suspense>
                  }
                />
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.service?.["list-patient"],
                users.profile?.authority?.service?.["nursing-care"],
                !!users.profile?.authority?.service?.["medical-record"],
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.treatment,
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.history,
                users.profile?.authority?.service?.["laboratorium"],
                users.profile?.authority?.service?.["pharmacy-transactions"],
              ].some((item) => item)) && (
              <Route path="services">
                <Route path="rawat-jalan">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatJalanAntrian />
                      </Suspense>
                    }
                  />
                  {(users.profile?.entity === "doctor" ||
                    users.profile?.entity === "nurse" ||
                    users.profile?.authority?.service?.["nursing-care"] ||
                    !!users.profile?.authority?.service?.["medical-record"]
                      ?.treatment) && (
                    <Route
                      path=":id/:type"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <RawatJalanPemeriksaan />
                        </Suspense>
                      }
                    />
                  )}

                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatJalanAdministrasi />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="oncall">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <OncallAntrian />
                      </Suspense>
                    }
                  />
                  {(users.profile?.entity === "doctor" ||
                    users.profile?.entity === "nurse" ||
                    users.profile?.authority?.service?.["nursing-care"] ||
                    !!users.profile?.authority?.service?.["medical-record"]
                      ?.treatment) && (
                    <Route
                      path=":id/:type"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <OncallPemeriksaan />
                        </Suspense>
                      }
                    />
                  )}

                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <OncallAdministrasi />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="laboratorium">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <LaboratoriumAntrian />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/:type"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <LaboratoriumPemeriksaan />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <LaboratoriumAdministrasi />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="ugd">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <AntrianUGD />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <UGDAdministrasi />
                      </Suspense>
                    }
                  />

                  {!!users.profile?.authority?.service?.["medical-record"] && (
                    <Route
                      path=":id/:type"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <UGDPemeriksaan />
                        </Suspense>
                      }
                    />
                  )}
                </Route>

                <Route path="rawat-inap">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <AntrianRawatInap />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":id/:type"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatInapPemeriksaan />
                      </Suspense>
                    }
                  />

                  <Route
                    path=":id/administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <RawatInapAdministrasi />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="instalasi-farmasi">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <ServicesInstalasiFarmasi />
                      </Suspense>
                    }
                  />
                  {(users.profile?.entity === "owner" ||
                    users.profile?.authority?.service?.[
                      "pharmacy-transactions"
                    ]) && (
                    <Route
                      path=":id"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ServicesInstalasiFarmasiForm />
                        </Suspense>
                      }
                    />
                  )}
                </Route>
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.pos?.create,
                users.profile?.authority?.pos?.logs,
              ].some((item) => item)) && (
              <Route path="pos">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path="transactions"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesTransactions />
                    </Suspense>
                  }
                />
                <Route
                  path="waiting"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <PointOfSalesWaiting />
                    </Suspense>
                  }
                />

                <Route path="logs">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesLogs />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesLogDetails />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="receivables">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesReceivables />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <PointOfSalesLogDetails />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.manage?.doctor,
                users.profile?.authority?.manage?.nurse,
                users.profile?.authority?.manage?.staff,
                users.profile?.authority?.manage?.patient,
                users.profile?.authority?.manage?.iks,
                users.profile?.authority?.manage?.["rumah-sakit"],
                users.profile?.authority?.manage?.["poli-rumah-sakit"],
                users.profile?.authority?.manage?.products,
                users.profile?.authority?.manage?.units,
                users.profile?.authority?.manage?.["product-categories"],
              ].some((item) => item)) && (
              <Route path="manages">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  [
                    users.profile?.authority?.manage?.doctor,
                    users.profile?.authority?.manage?.nurse,
                    users.profile?.authority?.manage?.staff,
                  ].some((item) => item)) && (
                  <Route path="employees">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaff />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaffNew />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":idUser"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataStaffDetails />
                        </Suspense>
                      }
                    />
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  [users.profile?.authority?.manage?.patient].some(
                    (item) => item
                  )) && (
                  <Route path="patients">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasien />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienDetails />
                        </Suspense>
                      }
                    />
                    <Route path=":idPatient">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienDetails />
                          </Suspense>
                        }
                      />
                      <Route
                        path="package-balance"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienPackageBalance />
                          </Suspense>
                        }
                      />
                      <Route
                        path="general-consent"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPasienGeneralConsent />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                <Route
                  path="iks"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                <Route
                  path="hospitals"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenData />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.polies) && (
                  <Route path="polies">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPoli />
                        </Suspense>
                      }
                    />
                    {clinics.selected &&
                      (clinics.selected.typeFaskes !== "praktek-mandiri" ||
                        (clinics.selected.typeFaskes === "praktek-mandiri" &&
                          clinics.selected.poli.length <= 0)) && (
                        <Route
                          path="new"
                          element={
                            <Suspense fallback={<LoadingOutlet />}>
                              <ManajemenDataPoliDetails />
                            </Suspense>
                          }
                        />
                      )}
                    <Route path=":idPoli">
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataPoliDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.iks) && (
                  <Route path="iks">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataIks />
                        </Suspense>
                      }
                    />
                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataIksDetails />
                        </Suspense>
                      }
                    />
                    <Route path=":idIks">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataIksDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                <Route
                  path="inpatients"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <ManajemenDataRuangInap />
                    </Suspense>
                  }
                />

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.products) && (
                  <Route path="products">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataProducts />
                        </Suspense>
                      }
                    />

                    <Route
                      path="master"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataProducts />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataProductDetails />
                        </Suspense>
                      }
                    />

                    <Route path=":idProduct">
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataProductDetails />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="units">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataProductUnits />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="types">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataProductTypes />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.suppliers) && (
                  <Route path="suppliers">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplier />
                        </Suspense>
                      }
                    />

                    <Route
                      path="purchase-order"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplierPurchaseOrder />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataSupplierCreateUpdate />
                        </Suspense>
                      }
                    />

                    <Route path=":idSupplier">
                      <Route
                        path="view"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierView />
                          </Suspense>
                        }
                      />
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierCreateUpdate />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.manage?.documents) && (
                  <Route path="documents">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataDocumentTemplates />
                        </Suspense>
                      }
                    />

                    <Route
                      path="new"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataDocumentTemplatesCreateUpdate />
                        </Suspense>
                      }
                    />

                    <Route path=":idDocument">
                      {/* <Route
                        path="view"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataSupplierView />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="edit"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ManajemenDataDocumentTemplatesCreateUpdate />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.reports?.sales,
                users.profile?.authority?.reports?.services,
                users.profile?.authority?.reports?.administrasi,
                users.profile?.authority?.reports?.["kunjungan-pasien"],
                users.profile?.authority?.reports?.["tindakan"],
                users.profile?.authority?.reports?.["apotek"],
              ].some((item) => item)) && (
              <Route path="reports">
                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.services) && (
                  <Route path="services">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsPelayananRawatJalan />
                        </Suspense>
                      }
                    />
                    <Route path="rawat-jalan">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalan />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalan />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananRawatJalanKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="ugd">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGD />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGD />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananUGDKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="oncall">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncall />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncall />
                          </Suspense>
                        }
                      />
                      <Route
                        path="kunjungan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPelayananOncallKunjungan />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.sales) && (
                  <Route path="sales">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsPenjualanRawatJalanOverview />
                        </Suspense>
                      }
                    />
                    <Route path="rawat-jalan">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanOverview />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanTindakan />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatJalanPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="rawat-inap">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapOverview />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapTindakan />
                          </Suspense>
                        }
                      /> */}
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanRawatInapPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="ugd">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDDetails />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path="puyer"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanUGDPuyer />
                          </Suspense>
                        }
                      /> */}
                    </Route>

                    <Route path="oncall">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="tindakan"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanOncallDetails />
                          </Suspense>
                        }
                      />
                    </Route>

                    <Route path="pos">
                      <Route
                        index
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungOverview />
                          </Suspense>
                        }
                      />
                      <Route
                        path="overview"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungOverview />
                          </Suspense>
                        }
                      />

                      <Route
                        path="details"
                        element={
                          <Suspense fallback={<LoadingOutlet />}>
                            <ReportsPenjualanLangsungDetails />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.administrasi) && (
                  <Route
                    path="administrasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <ReportsPenerbitanSurat />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.entity === "doctor" ||
                  !!users.profile?.authority?.reports?.["tindakan"]) && (
                  <Route
                    path="bagi-hasil-tindakan"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <ReportsBagiHasilTindakan />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  !!users.profile?.authority?.reports?.apotek) && (
                  <Route path="apotek">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="overview"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="logs"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <LogsApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="puyer"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <PuyerApotek />
                        </Suspense>
                      }
                    />
                    <Route
                      path="drugs-condition"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ReportsApotekDrugsCondition />
                        </Suspense>
                      }
                    />
                  </Route>
                )}
              </Route>
            )}

            {(users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.settings?.general,
                users.profile?.authority?.settings?.logo,
                users.profile?.authority?.settings?.bpjs,
                users.profile?.authority?.settings?.["instalasi-farmasi"],
                users.profile?.authority?.settings?.["coding-rm"],
                users.profile?.authority?.settings?.["point-of-sales"],
                users.profile?.authority?.settings?.["calendars"],
                users.profile?.authority?.settings?.["kiosk"],
                // users.profile?.authority?.settings?.["rawat-jalan"],
                users.profile?.authority?.settings?.["rawat-inap"],
                users.profile?.authority?.settings?.["hak-kewajiban-pasien"],
                users.profile?.authority?.settings?.["kiosk"],
                users.profile?.authority?.settings?.["announcers"],
                // users.profile?.authority?.settings?.notifications,
              ].some((item) => item)) && (
              <Route path="settings">
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.general) && (
                  <Route
                    path="general"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsGeneral />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.logo) && (
                  <Route
                    path="logo"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsLogo />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.[
                    "instalasi-farmasi"
                  ]) && (
                  <Route
                    path="instalasi-farmasi"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsInstalasiFarmasi />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["coding-rm"]) && (
                  <Route
                    path="coding-rm"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsCodingRM />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["point-of-sales"]) && (
                  <Route
                    path="point-of-sales"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsPointOfSales />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.bpjs) && (
                  <Route
                    path="bpjs"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsBPJS />
                      </Suspense>
                    }
                  />
                )}
                {/* {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["rawat-jalan"]) && (
                  <Route path="rawat-jalan" element={<SettingsRawatJalan />} />
                )} */}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["rawat-inap"]) && (
                  <Route
                    path="rawat-inap"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsRawatInap />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.[
                    "hak-kewajiban-pasien"
                  ]) && (
                  <Route
                    path="hak-kewajiban-pasien"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsHakKewajibanPasien />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["ugd"]) && (
                  <Route
                    path="ugd"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsUGD />
                      </Suspense>
                    }
                  />
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["oncall"]) && (
                  <Route
                    path="oncall"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsOnCall />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["calendars"]) && (
                  <Route
                    path="calendars"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsCalendars />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["kiosk"]) && (
                  <Route
                    path="kiosk"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsKiosk />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["announcers"]) && (
                  <Route
                    path="announcers"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsAnnouncers />
                      </Suspense>
                    }
                  />
                )}

                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["pemeriksaan"]) && (
                  <Route
                    path="pemeriksaan"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <SettingsPagePemeriksaan />
                      </Suspense>
                    }
                  />
                )}

                {/* {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.settings?.["notifications"]) && (
                  <Route
                    path="notifications"
                    element={<SettingsNotifications />}
                  />
                )} */}
              </Route>
            )}

            <Route path="integrations">
              <Route path="bpjs">
                <Route path="antrean-online">
                  <Route
                    path="settings"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSAntreanOnlineSettings />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="icare">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSiCareSettings />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="pcare">
                  <Route
                    path="settings"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSSettings />
                      </Suspense>
                    }
                  />
                  <Route
                    path="encounter"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSEncounter />
                      </Suspense>
                    }
                  />
                </Route>

                <Route path="vclaim">
                  <Route
                    path="settings"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <IntegrationsBPJSVCLAIMSettings />
                      </Suspense>
                    }
                  />

                  <Route path="sep">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMSepList />
                        </Suspense>
                      }
                    />
                    <Route
                      path=":noSep"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMSepDetails />
                        </Suspense>
                      }
                    />
                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMSepList />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route path="rencana-kontrol">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRencanaKontrolList />
                        </Suspense>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRencanaKontrolList />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route path="reference">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukanList />
                        </Suspense>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukanList />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route path="reference-exclusive">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukanKhususList />
                        </Suspense>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukanKhususList />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route path="prb">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukBalikList />
                        </Suspense>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMRujukBalikList />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route path="lpk">
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMLembarPengajuanKlaimList />
                        </Suspense>
                      }
                    />

                    <Route
                      path="list"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <IntegrationsBPJSVCLAIMLembarPengajuanKlaimList />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="crm">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Crm />
                    </Suspense>
                  }
                />
                <Route
                  path="overview"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Crm />
                    </Suspense>
                  }
                />
                <Route
                  path="marketing-campaign"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <MarketingCampaign />
                    </Suspense>
                  }
                />
                <Route
                  path="logs"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmRiwayat />
                    </Suspense>
                  }
                />
                <Route path="communication">
                  <Route
                    index
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateLists />
                      </Suspense>
                    }
                  />
                  <Route
                    path="create"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateCreateUpdate />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<LoadingOutlet />}>
                        <CommunicationTemplateCreateUpdate />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="feedback"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmFeedback />
                    </Suspense>
                  }
                />
                <Route
                  path="survey"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmSurvey />
                    </Suspense>
                  }
                />
                <Route
                  path="chatbot"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmChatbot />
                    </Suspense>
                  }
                />
                <Route
                  path="loyalty"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmLoyalty />
                    </Suspense>
                  }
                />
                <Route
                  path="omnichannel"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmOmnichannel />
                    </Suspense>
                  }
                />
                <Route
                  path="segmentation"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <CrmSegmentation />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="landing-page">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsLandingPage />
                    </Suspense>
                  }
                />
                <Route
                  path="welcome"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsLandingPage />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="satusehat">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsSatuSehat />
                    </Suspense>
                  }
                />
                <Route
                  path="welcome"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <IntegrationsSatuSehatWelcome />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="accountings">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Accountings />
                    </Suspense>
                  }
                />
                <Route
                  path="welcome"
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Accountings />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {(users.profile?.entity === "owner" ||
              [users.profile?.authority?.histories].some((item) => item)) && (
              <Route path="histories">
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingOutlet />}>
                      <Histories />
                    </Suspense>
                  }
                />
              </Route>
            )}
          </Route>

          <Route path="*" element={<AuthorizedOnly />} />
        </Route>

        <Route path="login" element={<Login />} />

        {/* <Route
          path="help"
          element={
            <Suspense fallback={<LoadingOutlet />}>
              <Help />
            </Suspense>
          }
        /> */}

        <Route path="blogs">
          <Route
            index
            element={
              <Suspense fallback={<LoadingOutlet />}>
                <Blogs />
              </Suspense>
            }
          />
          <Route
            path=":slug"
            element={
              <Suspense fallback={<LoadingOutlet />}>
                <BlogsSlug />
              </Suspense>
            }
          />
        </Route>

        <Route path="about">
          <Route
            index
            element={
              <Suspense fallback={<LoadingOutlet />}>
                <About />
              </Suspense>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      {locationState?.backgroundLocation && (
        <Routes>
          <Route path="clinics">
            <Route path=":id">
              <Route path="manages">
                <Route path="patients">
                  <Route path=":idPatient">
                    <Route
                      path="medical-records"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienModalMedicalRecords />
                        </Suspense>
                      }
                    />
                    <Route
                      path="general-consent"
                      element={
                        <Suspense fallback={<LoadingOutlet />}>
                          <ManajemenDataPasienModalGeneralConsent />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
    </div>
  );
}
